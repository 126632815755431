import './bootstrap';
import '../css/app.css';

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { NotificationProvider } from '@/Utils/NotificationContext';
import '@/Utils/suppressWarnings';

const appName = import.meta.env.VITE_APP_NAME || 'UHL Portal';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup: async ({ el, App, props }) => {
        const root = createRoot(el);

        // Dynamically import bugsnag if not in local environment
        let ErrorBoundary;
        if (import.meta.env.VITE_APP_ENV !== 'local') {
            const { ErrorBoundary: LoadedErrorBoundary } = await import('@/Utils/bugsnag');
            ErrorBoundary = LoadedErrorBoundary;
        } else {
            // No-op error boundary when local
            ErrorBoundary = ({ children }) => <>{children}</>;
        }

        root.render(
            <ErrorBoundary>
                <NotificationProvider>
                    <App {...props} />
                </NotificationProvider>
            </ErrorBoundary>
        );
    },
    progress: {
        color: '#4B5563',
    },
});
